import '../../styles/pages/spring-summer-2023/i-am-and-always-will-be-a-titan.scss';
import React from "react";
import Layout from '../../components/layout';
import { Helmet } from "react-helmet"
import ScrollAnimation from 'react-animate-on-scroll';
import MoreStories from "../../components/spring-summer-2023-stories/more-stories"
import Gallery from "react-photo-gallery";
import Vimeo from '@u-wave/react-vimeo';

import Hero from '../../images/spring-summer-2023/i-am-and-always-will-be-a-titan/hero.jpg';
import titelGraphic from '../../images/spring-summer-2023/i-am-and-always-will-be-a-titan/title-graphic.png';
import commencement from '../../images/spring-summer-2023/i-am-and-always-will-be-a-titan/commencement.jpg';
import itat from '../../images/spring-summer-2023/i-am-and-always-will-be-a-titan/itat.jpg';
import ProjectRebound from '../../images/spring-summer-2023/i-am-and-always-will-be-a-titan/project-rebound.jpg';
import SuperSunday from '../../images/spring-summer-2023/i-am-and-always-will-be-a-titan/super-sunday.jpg';
import socialBanner from "../../images/spring-summer-2023/social-banners/i-am-and-always-will-be-a-titan.jpg";

export default class Index extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
    };
  }

  render() {
    var pageTitle = 'CSUF President Fram Virjee Bids Farewell';
    var pageDesc = 'Retiring President Fram Virjee and First Lady Julie reflect on their memories of Cal State Fullerton.';
    var slug = 'i-am-and-always-will-be-a-titan';

    var heroStyles = {
      backgroundImage: `url(${Hero})`,
    }

    const photos = [
      {
        src: commencement,
        width: 1000,
        height: 709,
        alt: '',
        title: ''
      },
      {
        src: itat,
        width: 710,
        height: 1000,
        alt: '',
        title: ''
      },
      {
        src: ProjectRebound,
        width: 1000,
        height: 651,
        alt: '',
        title: ''
      },
      {
        src: SuperSunday,
        width: 1000,
        height: 667,
        alt: '',
        title: ''
      }
    ];

    return (
      <Layout headerStyle="white" locationInfo={this.props.location} issue="spring-summer-2023">
        <Helmet>
          <title>{pageTitle}</title>
          <body className={`spring-summer-2023 ${slug}`} />

          <meta name="description" content={pageDesc} />

          {/* Schema.org markup for Google+ */}
          <meta itemprop="name" content={pageTitle} />
          <meta itemprop="description" content={pageDesc} />
          <meta itemprop="image" content={socialBanner} />

          {/* Twitter Card data */}
          <meta name="twitter:title" content={pageTitle} />
          <meta name="twitter:description" content={pageDesc} />
          <meta name="twitter:image:src" content={socialBanner} />

          {/* Open Graph data */}
          <meta property="og:title" content={pageTitle} />
          <meta property="og:image" content={socialBanner} />
          <meta property="og:image:alt" content={pageTitle} />
          <meta property="og:description" content={pageDesc} />
        </Helmet>
        
        <div className="hero story animated fadeIn" style={heroStyles}>
          <div className="text">
            <h1 className="animated fadeInDown delay-1s">
              <img src={titelGraphic} alt="‘I Am and Always Will Be A Titan’" />
            </h1>
            <span className="animated fadeInDown delay-1s byline">By Valerie Orleans / Photos By Matt Gush</span>
          </div>
          <div className="tint"></div>
        </div>
        <article className="wrap small">

        <p><span className="intro-text">Cal State Fullerton President</span> Fram Virjee didn’t exactly fit the mold of a traditional university president. Instead of coming up through the faculty ranks, he spent decades as a successful litigator and then several years as general counsel and executive vice chancellor for the California State University system. Five years ago, he was asked by the chancellor to serve as president, and following a national search, was appointed as the sixth permanent president by the CSU Board of Trustees.</p>

        <blockquote className='pull-quote align-right align-outside'>
          <p>Although I may be leaving my role as president, I am and always will be a Titan. I have so many wonderful memories from my years at Cal State Fullerton, and I am optimistic about the university’s future. We are finding our way to becoming the truly inclusive community that we dream of and the beloved community we strive for. We are poised for limitless greatness.</p>
        </blockquote>


        <p>What makes him different from many presidents may be what has contributed to his success. Faculty, staff and students appreciate his student-centered approach, down-to-earth style, open-door policy, attention to detail, willingness to listen, great sense of humor, openness to change and unwavering commitment to social justice.</p>

        <p>Under his leadership, Cal State Fullerton looks different. The campus has been transformed with studying nooks, new landscape, touchdown spaces and digital signage everywhere. There is the renovated second floor of McCarthy Hall, a new 600-bed student housing structure, a new aquatics center, renovated softball and baseball complexes, an additional parking structure, the iconic Titan Gateway arch on the promenade, and a new food pantry. The southside of Pollak Library reopened after about four years, the visual arts modernization project is underway, and plans are being developed to create a new Engineering and Computer Science Innovation Hub. But the <a href="https://titanmag.fullerton.edu/fall-winter-2021/9-projects-transforming-the-campus/" target='_blank' rel="noreferrer">campus changes</a> aren’t just physical.</p>

        <figure className="align-wide">
          <Vimeo
            video="820753003"
            autoplay={false}
            responsive={true}
            showPortrait={false}
            showTitle={false}
            showByline={false}
          />
          {/* <figcaption>Alumna Denyz Melchor's GWPAC story</figcaption> */}
        </figure>

        <p>His willingness to tackle difficult challenges has led him to successfully steer the campus through a <a href="https://news.fullerton.edu/2020/05/fram-virjee-oc-50/" target='_blank' rel="noreferrer">pandemic</a>, budget cuts and the return of mostly in-person classes after almost 18 months of virtual learning.</p>

        <p>Amazingly, despite these challenges, the university has seen a 53% increase in the four-year graduation rate for first-time freshmen since spring 2018; the expansion of transformational programs and opportunities for students, especially first-generation students, students of color and marginalized groups; and the completion of Cal State Fullerton’s first <a href="https://campaign.fullerton.edu/" target='_blank' rel="noreferrer">comprehensive campaign</a>, which raised more than $270 million. And those are just a few examples.</p>

        <p>What the campus and community will remember most about Virjee is his deep commitment to the role that higher education plays not only for graduating students but for their families and the generations that follow.</p>
        
        <ScrollAnimation animateOnce={true} animateIn="fadeIn">
          <figure className="align-wide gallery">
            <Gallery photos={photos}  />
            <figcaption>
              <strong>Top Left:</strong> More than 45,000 Titans have graduated during President Fram Virjee’s tenure. 
              <strong>&nbsp;Top Right:</strong> With Virjee at the helm, the campus successfully completed the “It Takes a Titan” comprehensive campaign, which raised $270 million. 
              <strong>&nbsp;Bottom Left:</strong> Virjee and campus leaders celebrate the opening of Project Rebound’s Center for Hope and Redemption in Pollak Library. 
              <strong>&nbsp;Bottom Right:</strong> Virjee connects with a student at “Super Sunday,” a California State University effort to visit Black churches throughout California and share the importance of preparing for college.
            </figcaption>
          </figure>
        </ScrollAnimation>

        <p>“I have so many great memories — too many to mention, but I will try,” says Virjee, noting that meeting and talking with students usually is the highlight of his day.</p>

        <p>He recalls working closely with students in <a href="https://titanmag.fullerton.edu/spring-2020/boundless-opportunity/" target='_blank' rel="noreferrer">Project Rebound</a> (a program to assist formerly incarcerated students in attaining a college education), attending nearly every theater performance and even appearing in a production of “Beauty and the Beast,” listening to student musicians in Meng Hall and attending senior recitals, meeting and laughing with President’s Scholars, cheering on the first-ever women’s water polo team at their first home game — and celebrating their win. He remembers watching the Titan baseball team beat Stanford at Stanford, when a freshman player with two strikes and two outs in the ninth hit the ball out of the park and the crowd went wild. He even enjoys meeting with the Academic Senate. (Really!)</p>

        <p>“A special memory is when the Titan family joined softball player Taylor Dockins, who was battling liver cancer, for the ‘No One Fights Alone’ night in her honor,” Virjee recalls. “Her dad threw out the first pitch, her aunt sang the national anthem, and players from Fullerton and UC Santa Barbara wore green ribbons (the awareness color for liver cancer) in their hair. Everyone left with a wristband inscribed with #TaylorStrong.</p>

        <p>“This is emblematic of the Titan spirit — we come together to work hard, celebrate our accomplishments and support one another through adversity,” he reflects.</p>

        <p>“Fram is beloved not only by members of the Titan family, but by the Orange County community and educators throughout the nation for his service, support and dedication to Cal State Fullerton and the larger community,” said Tam Nguyen ’05 (MBA), co-founder and chair of Advance Beauty College and chair of the CSUF Philanthropic Foundation board of governors.</p>

        <p>“As I prepare to retire from this campus that I have grown to love over the past five years, the Titan community not only knows who we are but where we’re going,” Virjee says. “We have moved from a transactional campus to a place of transformation. Our commitment to diversity, equity, inclusion and social justice is on full display.” <span className="end-mark" /></p>


        </article>
        <section id="julie-sidebar">
          <div className='sidebar-story-image' />
          <div className='sidebar-story-text'>
            <h2>First Lady Forever Inspired by Student Stories</h2>

            <p>If you ask Julie Virjee, wife of CSUF President Fram Virjee, what her most memorable experiences are from her five years on campus, she responds immediately, “Anywhere there are students.”</p>

            <p>Whether she is on a bus in Spain with “42 of my favorite students,” attending student performances, hanging out at the Titan baseball and softball fields, mentoring, or consoling and finding help for a student in distress, Fullerton’s “first lady” is frequently in the company of one (or more!) of the university’s 40,000 students.</p>

            <p>“I love engaging with our students,” she says. “You hear their stories, how they are coping, their successes and what challenges they are facing. While I cannot solve all their issues, I want them to know someone cares about them.”</p>

            <p>During her time on campus, she advocated for a food pantry for students experiencing food insecurity, and she succeeded in having compostable plates and forks in university eating establishments and residence halls. She speaks to campus groups and classes, and mentors students in the College of Business and Economics’ Executive in Residence program.</p>

            <p>As the mother of four grown sons, the founder of Yambi Rwanda (a not-for-profit organization dedicated to transcending genocide and overcoming poverty in Rwanda) and a lifelong learner, she thrives on the energy of Cal State Fullerton’s campus.</p>

            <p>“There is always so much happening,” she says. “For me, the university is like Disneyland. There are so many opportunities to learn. Sometimes I hate to leave the campus because I am afraid I will miss something.”</p>

            <p>A few years ago, she was asked to deliver opening remarks at an event where engineering students were presenting their capstone projects — the result of engineering designs they were working on.</p>

            <p>“I loved being a student and studied lots of different subjects in college — geology, anthropology, communications — but I had not taken any engineering classes, so that was a little intimidating,” she says. “And yet, when I met the students and asked about their work, they were so excited to explain what they were trying to accomplish. I learned so much that I continued to attend these presentations.</p>

            <p>“Fram and I want the students to know how much they mean to us. They are amazing and we love them,” she emphasizes. “It has been such an honor to get to know them and hear their stories.</p>

            <p>“I want every student to know that they are capable of amazing things … the future is theirs.” <span className="end-mark" /></p>
          </div>
        </section>
        <MoreStories slug={slug} />
      </Layout>
    );
  }
} 